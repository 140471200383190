import type { BaseData, IMainSubStructureModel, NormalProgrammeParams } from '../../utils';
import { request, SearchListModal } from '../../utils';
import { observable } from 'mobx';
// import styles from './userManageErp/index.less';
import { Button, Switch } from 'antd';
import React from 'react';
import AnchorEditorModel from './modal/model';
import RelevanceManageStore from './modal/relevanceManageStore';
import { API } from './api';

export default class Model {
  public editorStore = new AnchorEditorModel({ parent: this });

  public relevanceManageStore = new RelevanceManageStore({ parent: this });

  @observable public filterSet: Partial<NormalProgrammeParams> = {
    // count: 6,
    filterItems: [
      // {
      //   type: 'dateRange',
      //   label: '创建时间',
      //   field: 'createTimeDuration',
      //   format: 'YYYY-MM-DD',
      // },
      {
        type: 'input',
        label: '达人名称',
        field: 'anchorName',
      },
      {
        type: 'input',
        label: '达人ID',
        field: 'anchorPlatformId',
      },
      {
        type: 'select',
        label: '启用状态',
        field: 'enableStatus',
        data: [
          {
            label: '启用',
            value: '1',
          },
          {
            label: '禁用',
            value: '0',
          },
        ],
      },
      {
        type: 'select',
        label: '运营负责人',
        field: 'maintainerId',
      },
      {
        type: 'select',
        label: '实施负责人',
        field: 'implementerId',
      },
      {
        type: 'select',
        label: '线上运营',
        field: 'onlineOperatorId',
      },
    ],
  };

  public grid: IMainSubStructureModel = {
    buttons: [
      {
        // permissionId: '1508_4037',
        text: '新增',
        handleClick: () => {
          this.editorStore.onShow();
        },
        // display: (rows) => rows.every((el) => true),
      },
    ],
    grid: {
      columns: [
        {
          key: 'billingOrderNo',
          name: '操作',
          formatter: ({ row }) => (
            <div>
              {/* <Permission permissionId="1508_44"> */}
              <Button
                onClick={() => {
                  console.log('edit');
                  this.editorStore.onShow(row, 'edit');
                }}
                type="link"
              >
                编辑
              </Button>
              {/* </Permission> */}
              {/* <Permission permissionId="1508_6700"> */}
              <Button
                onClick={() => {
                  console.log('edit');
                  this.relevanceManageStore.onShow(row);
                }}
                type="link"
              >
                负责人配置
              </Button>
              {/* </Permission> */}
            </div>
          ),
        },
        {
          key: 'anchorName',
          name: '达人名称',
        },
        {
          key: 'anchorPlatformId',
          name: '达人ID',
        },
        {
          key: 'platformName',
          name: '所属平台',
        },
        {
          key: 'maintainer',
          name: '运营负责人',
        },
        {
          key: 'implementer',
          name: '实施负责人',
        },
        {
          key: 'onlineOperator',
          name: '线上运营',
        },
        {
          key: 'remark',
          name: '备注',
        },
        {
          key: 'enableStatus',
          name: '启用状态',
          formatter: ({ row }) => {
            const { enableStatus, anchorId } = row;
            return (
              <div style={{ textAlign: 'left' }}>
                {/* <Permission permissionId="1508_3"> */}
                <Switch
                  checked={enableStatus === 1}
                  checkedChildren="启用"
                  onChange={() => {
                    this.onSwitchChange(anchorId, enableStatus === 1 ? 0 : 1);
                  }}
                  unCheckedChildren="禁用"
                />
                {/* </Permission> */}
              </div>
            );
          },
        },
        {
          name: '创建时间',
          key: 'createTime',
        },
      ].map((v) => ({
        resizable: true,
        sortable: false,
        ...v,
      })),
      rows: [],
      primaryKeyField: 'anchorId',
      sortByLocal: false,
      showCheckBox: true,
      showEmpty: true,
      // showPager: false,
    },
    hiddenSubTable: true,
    api: {
      onQuery: (params) => {
        const { filterParams, ...rest } = params;
        const postParams = {
          ...filterParams,
          ...rest,
        };
        for (const k in postParams) {
          if (Array.isArray(postParams[k])) {
            postParams[k] = postParams[k].toString();
          }
        }
        return request<BaseData<any>>({
          url: API.queryAnchorPage,
          method: 'POST',
          data: { ...postParams },
        });
      },
    },
  };

  public pageStore = new SearchListModal({
    programme: this.filterSet,
    grid: this.grid,
  });

  private onSwitchChange = async (anchorId, enableStatus) => {
    const result = await request({
      url: API.switchAnchorStaus,
      method: 'PUT',
      params: {
        anchorId,
        enableStatus,
      },
    });
    this.pageStore.grid.onQuery();
  };
}
