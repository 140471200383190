export const API = {
  // 分页查询达人库
  queryAnchorPage: '/api/iac/anchor/page',

  // 新增/编辑达人库达人
  saveAnchor: '/api/iac/anchor/save',

  // 模糊匹配达人名称
  searchAnchorByName: '/api/iac/anchor/search/anchorName',

  // 开关达人库中达人
  switchAnchorStaus: '/api/iac/anchor/switch',

  // 获取全部平台
  queryAnchorAllPlatform: '/api/iac/anchor/allPlatform',
};
