import { SearchListStructure } from '../../utils/searchListStructure';
import { observer } from 'mobx-react';
import React, { useState } from 'react';
import Model from './model';
import { AnchorEditor, RelevanceManageModal } from './modal';

const AnchorLibrary = () => {
  const [store, setStore] = useState(new Model());
  const { pageStore, editorStore, relevanceManageStore } = store;
  return (
    <div>
      <SearchListStructure store={pageStore} />
      <AnchorEditor store={editorStore} />
      <RelevanceManageModal store={relevanceManageStore} />
    </div>
  );
};

export default observer(AnchorLibrary);
