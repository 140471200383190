import { message } from 'antd';
import type { FormInstance } from 'antd/lib/form';
import type { BaseData } from '../../../utils';
import { request } from '../../../utils';
import { observable, action } from 'mobx';
// import api from '../../../utils/api';

export default class RelevanceManageStore {
  constructor(options) {
    this.parent = options.parent;
    this.queryMaintainer();
  }

  @observable public parent;

  @observable public initialValues;

  @observable public anchorId: string;

  @observable public ref: FormInstance;

  @observable public visible = false;

  @observable public saveLoading = false;

  @observable public maintainerList = [];

  @action public setRef = (ref: FormInstance): void => {
    this.ref = ref;
  };

  @action public queryMaintainer = async (): Promise<void> => {
    const res: BaseData<Array<{ id: number; name: string }>> = await request({
      url: '',
      params: { tenantType: 1 },
    });
    const list = (res.data || []).map((v) => ({
      label: v.name,
      value: v.id.toString(),
    }));
    this.maintainerList = list;
  };

  @action public onShow = (row) => {
    if (row) {
      const { anchorId, maintainerId, implementerId, onlineOperatorId } = row;
      this.anchorId = anchorId;
      this.initialValues = {
        maintainerId: maintainerId?.toString(),
        implementerId: implementerId?.toString(),
        onlineOperatorId: onlineOperatorId?.toString(),
      };
    }
    this.visible = true;
  };

  @action public save = (): void => {
    const { maintainerId, implementerId, onlineOperatorId } = this.ref.getFieldsValue();
    this.saveLoading = true;
    request({
      url: '/api/iac/anchor/edit/responsible/person/info',
      method: 'post',
      data: {
        anchorId: this.anchorId,
        maintainerId,
        implementerId,
        onlineOperatorId,
      },
    })
      .then((res: any) => {
        message.success(res.info);
        this.onClose();
        this.parent?.pageStore.grid.onQuery();
      })
      .finally(() => {
        this.saveLoading = false;
      });
  };

  @action public onClose = (): void => {
    this.visible = false;
    this.ref.resetFields();
    this.anchorId = '';
  };

  @action public onOk = (): void => {
    this.ref?.validateFields().then((res) => {
      this.save();
    });
    console.log('ok');
  };
}
