import type { FormInstance } from 'antd';
import type { BaseData } from '../../../utils';
import { request } from '../../../utils';
import { action, observable } from 'mobx';
// import { API } from '../api';
// import api from '../../../utils/api';

interface LabelAndValue {
  label: string;
  value: string;
}

export default class AnchorEditorModel {
  @observable
  public visible = false;

  @observable
  public type = 'add';

  @observable
  public saveLoading = false; // 保存状态

  @observable public allPlatform: LabelAndValue[] = [];

  @observable public allMaintainer: LabelAndValue[] = []; // 所有运营人员

  @observable public ref: FormInstance;

  @observable public anchorId = null;

  @observable public parent = null;

  constructor({ parent }) {
    this.parent = parent;
    this.queryAnchorAllPlatform();
    this.queryMaintainer();
  }

  @action
  @action
  public setRef = (ref: FormInstance): void => {
    this.ref = ref;
  };

  @action
  public queryAnchorAllPlatform = async () => {
    const results = await request<BaseData<Array<{ key: number; value: string }>>>({ url: 'API.queryAnchorAllPlatform' });
    this.allPlatform = results.data?.map(({ key, value }) => ({
      label: value,
      value: key.toString(),
    }));
  };

  @action public queryMaintainer = async (): Promise<void> => {
    const res: BaseData<Array<{ id: number; name: string }>> = await request({
      url: 'api.getMaintainer',
      params: { tenantType: 1 },
    });
    const list = (res.data || []).map((v) => ({
      label: v.name,
      value: v.id.toString(),
    }));
    this.allMaintainer = list;
    this.parent.pageStore.programme.filterItems.addDict({ maintainerId: list });
    this.parent.pageStore.programme.filterItems.addDict({ implementerId: list });
    this.parent.pageStore.programme.filterItems.addDict({ onlineOperatorId: list });
  };

  @action
  public onShow = (data?: any, type = 'add') => {
    this.type = type;
    this.visible = true;
    if (type !== 'add') {
      setTimeout(() => {
        for (const key in data) {
          if (typeof data[key] === 'number') {
            data[key] = data[key].toString();
          }
        }
        this.ref.setFieldsValue(data);
      }, 0);
      this.anchorId = data.anchorId;
    }
  };

  @action
  public onClose = () => {
    this.ref.resetFields();
    this.visible = false;
  };

  @action
  public onSave = async (isContinued = false) => {
    const isValidated = await this.ref.validateFields();
    if (!isValidated) {
      return;
    }
    this.saveLoading = true;
    const values = this.ref.getFieldsValue();
    const { platformId } = values;
    let postParams = {
      ...values,
      platformId: Number(platformId),
    };
    if (this.type === 'edit') {
      postParams = {
        ...postParams,
        anchorId: this.anchorId,
      };
    }
    request({
      url: 'API.saveAnchor',
      method: 'POST',
      data: { ...postParams },
    })
      .then(() => {
        if (isContinued) {
          this.ref.resetFields();
        } else {
          this.onClose();
        }
        this.parent.pageStore.grid.onQuery();
      })
      .finally(() => {
        this.saveLoading = false;
      });
  };
}
