import { Button, Col, Form, Input, Modal, Row, Select, Space } from 'antd';
import { observer } from 'mobx-react';
import React from 'react';
import type Model from './model';
import type RelevanceManageStore from './relevanceManageStore';

const formLayout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};
const formItemLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 16 },
};
export const AnchorEditor = observer(({ store }: { store: Model }) => {
  if (!store) {
    return null;
  }
  const { visible, onClose, type, saveLoading, onSave, allPlatform, setRef } = store;
  console.log(store);
  return (
    <Modal
      centered
      footer={
        <Space>
          <Button onClick={onClose}>取消</Button>
          {type === 'add' && (
            <Button
              loading={saveLoading}
              onClick={() => onSave(true)}
              type="primary"
            >
              保存并继续
            </Button>
          )}
          <Button
            ghost
            loading={saveLoading}
            onClick={() => onSave()}
            type="primary"
          >
            保存
          </Button>
        </Space>
      }
      maskClosable={false}
      onCancel={onClose}
      open={visible}
      title={type === 'add' ? '新增达人' : '编辑达人'}
      width={600}
    >
      <Form ref={setRef}>
        <Row>
          <Col span={12}>
            <Form.Item
              label="达人名称"
              name="anchorName"
              rules={[{ required: true }]}
              validateTrigger="onBlur"
            >
              <Input
                autoComplete="off"
                className=""
                placeholder=""
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              {...formItemLayout}
              label="达人ID"
              name="anchorPlatformId"
              rules={[{ required: true }]}
              validateTrigger="onBlur"
            >
              <Input
                autoComplete="off"
                className=""
                placeholder=""
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <Form.Item
              {...formItemLayout}
              label="所属平台"
              name="platformId"
              rules={[{ required: true }]}
              validateTrigger="onBlur"
            >
              <Select
                allowClear
                optionFilterProp="label"
                options={allPlatform}
                placeholder="请选择"
                showSearch
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              {...formItemLayout}
              label="备注"
              name="remark"
              validateTrigger="onBlur"
            >
              <Input
                autoComplete="off"
                className=""
                placeholder="请输入备注"
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
});

export const RelevanceManageModal = observer(({ store }: { store: RelevanceManageStore }): JSX.Element => {
  const { visible, initialValues, saveLoading, onClose, onOk, setRef, maintainerList } = store;
  return (
    <Modal
      destroyOnClose
      footer={
        <Space>
          <Button onClick={onClose}>取消</Button>

          <Button
            ghost
            loading={saveLoading}
            onClick={onOk}
            type="primary"
          >
            保存
          </Button>
        </Space>
      }
      onCancel={onClose}
      open={visible}
      title="关联管理"
      width={600}
    >
      <Form
        ref={setRef}
        {...formLayout}
        initialValues={initialValues}
        size="middle"
      >
        <Row>
          <Col span={12}>
            <Form.Item
              label="运营负责人"
              name="maintainerId"
            >
              <Select
                allowClear
                optionFilterProp="label"
                options={maintainerList}
                showSearch
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="实施负责人"
              name="implementerId"
            >
              <Select
                allowClear
                optionFilterProp="label"
                options={maintainerList}
                showSearch
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <Form.Item
              label="线上运营"
              name="onlineOperatorId"
            >
              <Select
                allowClear
                optionFilterProp="label"
                options={maintainerList}
                showSearch
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
});
